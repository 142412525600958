






























































































































































import { createComponent, reactive, ref, computed } from '@vue/composition-api'
import moment from 'moment'
import { firebaseApp } from '@/plugins/firestore'
import PhoneHeader from '@/components/phone-header.vue'
import NewMessage from '@/components/new_message.vue'
import ReceivingTelephone from '@/components/phone-receiving.vue'

type User = {
  name: string
  icon: string
  currentTime: string
}

type NavigationItem = {
  title: string
  icon: string
  items: { title: string; role: string }[]
}

export default createComponent({
  components: {
    'cti-phone-header': PhoneHeader,
    'cti-new-message': NewMessage,
    'cti-receiving-telephone': ReceivingTelephone
  },
  setup(_props, context) {
    const waitLoadingPage = ref<boolean>(false)
    context.root.$nuxt.$on('waitLoadingPage', (isLoading: boolean = false) => {
      waitLoadingPage.value = isLoading
    })
    const dialogOpen = ref<boolean>(false)
    const isLoggedIn = context.root.$auth.loggedIn
    if (!isLoggedIn) {
      context.root.$router.push({
        path: '/login/'
      })
    }
    const user = reactive<User>({
      name: context.root.$auth.user ? context.root.$auth.user.username : '',
      icon: '../assets/img/operator.png', // Icon - navigation - Operator WHITE
      currentTime: moment().format('HH:mm')
    })
    setInterval(() => {
      user.currentTime = moment().format('HH:mm')
    }, 1000)
    const navItems = ref<NavigationItem[]>([
      {
        title: '予約・運行',
        icon: 'mdi-calendar',
        items: [
          { title: '車両別状況', link: '/overview/buses/' },
          { title: '利用者別状況', link: '/overview/passengers/' },
          { title: '配車状況', link: '/overview/reservations/' },
          { title: 'ドライバー日報', link: '/overview/daily_report/' }
        ]
      },
      {
        title: '管理機能',
        icon: 'mdi-account',
        items: [
          { title: '利用者管理', link: '/administration/users/' },
          { title: 'ドライバー管理', link: '/administration/drivers/' },
          { title: '停留所管理', link: '/administration/bus_stops/' },
          { title: '道路状況管理', link: '/administration/roads/' }
        ]
      }
    ])

    const isAdmin = computed(() => {
      return context.root.$auth.user ? context.root.$auth.user.is_admin : false
    })

    const adminMenuItems = ref<NavigationItem>({
      title: '設定・出力',
      icon: 'mdi-wrench',
      items: [
        {
          title: '車両設定',
          link: '/administration/buses/',
          role: 'All'
        },
        {
          title: '車両発着所設定',
          link: '/system/depots/',
          role: 'All'
        },
        {
          title: '運行計画設定',
          link: '/system/availabilities/',
          role: 'All'
        },
        {
          title: 'オペレーター設定',
          link: '/system/operators/',
          role: 'All'
        },
        /*
        {
          title: 'キオスク管理',
          link: '/system/agents/',
          role: 'All'
        },
        */
        {
          title: '料金種別管理',
          link: '/system/prices/',
          role: 'OnlyRoot'
        },
        {
          title: '運行地域管理',
          link: '/system/areas/',
          role: 'OnlyRoot'
        },
        {
          title: 'データ出力',
          link: '/system/statistics/',
          role: 'All'
        }
      ]
    })

    const fileredAdminMenuItems = computed(() => {
      return adminMenuItems.value.items.filter((item) =>
        context.root.$auth.user.username === 'root'
          ? ['OnlyRoot'].includes(item.role) || ['All'].includes(item.role)
          : ['OnlyAdmin'].includes(item.role) || ['All'].includes(item.role)
      )
    })

    // 電話ヘッダーのオブジェクトの取得
    const phoneComponent = ref<InstanceType<typeof PhoneHeader>>()
    const receivingComponent = ref<InstanceType<
      typeof ReceivingTelephone
    > | null>(null)

    const logout = async () => {
      // ログアウト時に確実に twilio を破棄する
      if (phoneComponent != null && phoneComponent.value != null) {
        console.log('logout... twilio device')
        phoneComponent.value.disposePhoneHeader()
      }
      await context.root.$store
        .dispatch('setOperatorAvailability', {
          available: false
        })
        .catch(console.error)
      await firebaseApp
        .auth()
        .signOut()
        .catch(console.error)
      context.root.$router.push('/login/')
      await context.root.$auth.logout().catch(console.error)
    }

    // 2023-07-24 受電時の処理を分離し追加 -Start-
    const phoneNumber = ref('')
    const callReceivingTelephoneMethod = (phone: string) => {
      phoneNumber.value = phone
      if (receivingComponent.value !== null) {
        // 直接receivingTelephoneMethod()を呼び出すため、引数にcontextも設定している
        // propsでは渡せない
        receivingComponent.value.receivingTelephoneMethod(phone, context)
      }
    }
    const callDisplayPartnerNamesMethod = (isDisplay: boolean) => {
      if (receivingComponent.value !== null) {
        receivingComponent.value.displayPartnerNames(isDisplay)
      }
    }
    // 2023-07-24 受電時の処理を分離し追加 -End-
    const checkLoginSameUser = async () => {
      const param = {
        app_type: 'dashboard'
      }
      const params = new URLSearchParams(param)
      await context.root.$axios
        .$post(`/auth/check_login_same_user`, params)
        .then((res) => {
          dialogOpen.value = res.result
        })
    }
    if (context.root.$auth.loggedIn) {
      checkLoginSameUser()
    }

    return {
      waitLoadingPage,
      isLoggedIn,
      user,
      navItems,
      isAdmin,
      adminMenuItems,
      fileredAdminMenuItems,
      logout,
      phoneComponent,
      phoneNumber,
      callReceivingTelephoneMethod,
      callDisplayPartnerNamesMethod,
      receivingComponent,
      dialogOpen
    }
  }
})
